import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject, throwError} from 'rxjs';
import {
    AnalogReading,
    AnalogScratchPad, AnalogScratchPadV2, ChannelReadingsViewModel,
    ChannelUpdateResponse, ChannelViewModel,
    DigitalReading,
    OmaResource,
    PendingAnalogReading,
    PendingAnalogReadingResponse,
    PendingAnalogScratchPad,
    PendingDigitalReading,
    PendingDigitalReadingResponse
} from './readings.model';
import {Unit} from '@ai/ngx-concentric/lib/models/unit';
import * as _ from 'lodash';
import {environment} from '../../../../../environments/environment';
import {ApiService, Response} from '@ai/ngx-concentric';
import {UnitDataPoint} from '../../../../core/models/unit-data-point.model';
import {DataPointEnum} from '../../../../core/enums/data-point.enum';
import {InputTypeEnum} from '../../../../core/enums/input-type.enum';
import {FormGroup} from '@angular/forms';
import {AnalogScratchpadEnum, MultiplierConstants} from './readings.enums';
import {PcsLabel} from '../channel-settings/channel-settings.models';
import {catchError, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import { UnitStyleEnum } from 'app/core/enums/unit-style.enum';
import { map } from 'rxjs/operators';

declare let require: any;
const parseString = require('xml2js').parseString;

@Injectable({providedIn: 'root'})
export class ReadingsService {
    private _dataPointEnum = DataPointEnum;
    private _measurements = new BehaviorSubject<ChannelUpdateResponse>({
        type: null,
        channel: null,
        measurement: null,
        isEnabled: false,
        reading: null
    });
    private _measurement$ = this._measurements.asObservable();

    constructor(private _httpClient: HttpClient,
                private _apiService: ApiService) {
    }

    private _channelViewModel: ReplaySubject<ChannelViewModel> = new ReplaySubject<ChannelViewModel>(1);

    // -----------------------------------------------------------------------------------------------------
    // Setter for the Channel View Model
    // -----------------------------------------------------------------------------------------------------
    set channelViewModel(value: ChannelViewModel) {
        this._channelViewModel.next(value);
    }

    // -----------------------------------------------------------------------------------------------------
    // Getter for the Channel View Model
    // -----------------------------------------------------------------------------------------------------
    get channelViewModel$(): Observable<ChannelViewModel> {
        return this._channelViewModel.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // Get the settings
    // -----------------------------------------------------------------------------------------------------
    public getMeasurements(): Observable<any> {
        return this._measurement$;
    }

    // -----------------------------------------------------------------------------------------------------
    // Sets the settings
    // -----------------------------------------------------------------------------------------------------
    public setMeasurements(value: ChannelUpdateResponse): void {
        this._measurements.next(value);
    }

    // -----------------------------------------------------------------------------------------------------
    // Gets the analog property values
    // -----------------------------------------------------------------------------------------------------
    public parseAnalogPropertyValue(item: any, value: any, reading: any, measurement: string, channelProperties: any): any {
        // Check if the value is a bool
        if (item.resource.toLowerCase().indexOf('enable') >= 0) {
            if (!isNaN(value)) {
                value = value ? 1 : 0;
            }
        }

        // Decode scratchpad
        const isScratchPadEmpty = channelProperties?.['scratchpad_2']?.[0] === '' || null || undefined;
        const scratchPad = isScratchPadEmpty ? {} as AnalogScratchPad : this.decodeScratchPad(channelProperties?.['scratchpad_2']?.[0]);

        // Get the value
        if (item.resource.toLowerCase().indexOf('threshold') >= 0) {
            value = this.removeScaling(value, scratchPad, measurement, true);
        }
        if (item.resource.toLowerCase().indexOf('deadband') >= 0) {
            value = this.removeScaling(value, scratchPad, measurement, false);
        }
        if (item.resource.toLowerCase().indexOf('persistence') >= 0) {
            value = value / 60;
        }

        // Return the value
        return value;
    }

    // -----------------------------------------------------------------------------------------------------
    // Build the analog form controls
    // -----------------------------------------------------------------------------------------------------
    public getAnalogFormControls(unit: Unit, channelIndex: number, measurement: string): PendingAnalogReadingResponse {
        let desiredProperties = null;
        let reportedProperties = null;

        if (unit && unit.unitSettings) {
            reportedProperties = unit.unitSettings?.['properties']?.['reported']?.['analog_acdc_31032'];
            desiredProperties = unit.unitSettings?.['properties']?.['desired']?.['analog_acdc_31032'];
        }

        const reportedReading = {} as AnalogReading;
        const desiredReading = {} as PendingAnalogReading;

        let index = `${channelIndex}`;
        if (unit.brand.unitStyleId == UnitStyleEnum.Dome  || unit.brand.unitStyleId == UnitStyleEnum.IOModule)
        {
            index = `100${channelIndex}`
        }

        const reportedChannel = reportedProperties?.[index];
        const desiredChannel = desiredProperties?.[index];

        // channel_1
        reportedReading.channel_1 = reportedChannel ? reportedChannel?.channel_1 : `Channel ${channelIndex}`;

        // Iterate through all the OMA_XML resources
        _.forEach(this.getAnalogChannelResourceList(), (item) => {
            let reportedValue = null;
            let desiredValue = null;
            if (reportedChannel?.[item.resource] !== undefined) {
                reportedValue = this.setValue(reportedReading[item.resource] = reportedChannel ? reportedChannel?.[item.resource] : item.defaultValue, item.dataType);
                reportedValue = this.parseAnalogPropertyValue(item, reportedValue, reportedReading, measurement, reportedChannel);
                reportedReading[item.resource] = reportedValue;
            }
            if (desiredChannel?.[item.resource] !== undefined) {
                desiredValue = this.setValue(desiredReading[item.resource] = desiredChannel ? desiredChannel?.[item.resource] : item.defaultValue, item.dataType);
                desiredValue = this.parseAnalogPropertyValue(item, desiredValue, desiredReading, measurement, desiredChannel);
                desiredReading[item.resource] = desiredValue !== reportedValue;
            }
        });

        // Get reported values from scratch pad
        const reportedChannelScratchPad = reportedChannel?.['scratchpad_2']?.[0] === null ? '{}' : !!reportedChannel?.['scratchpad_2']?.[0] ? reportedChannel?.['scratchpad_2']?.[0] : '{}';
        const reportedReadingsScratchPad = this.decodeScratchPad(reportedChannelScratchPad);

        // Get desired values from scratch pad
        const desiredChannelScratchPad = desiredChannel?.['scratchpad_2']?.[0] === null ? '{}' : !!desiredChannel?.['scratchpad_2']?.[0] ? desiredChannel?.['scratchpad_2']?.[0] : '{}';
        const desiredReadingsScratchPad = this.decodeScratchPad(desiredChannelScratchPad);

        // Initialize
        reportedReading['scratchpad_2'] = {} as AnalogScratchPad;
        desiredReading['scratchpad_2'] = {} as PendingAnalogScratchPad;

        // Iterate through all the OMA_XML resources
        _.forEach(this.getAnalogChannelScratchPadResourceList(), (resource) => {
            reportedReading['scratchpad_2'][resource.resource] = reportedReadingsScratchPad?.[resource.resource] ?? resource.defaultValue;
            if (desiredChannelScratchPad === '{}') {
                desiredReading['scratchpad_2'][resource.resource] = false;
            } else {
                desiredReading['scratchpad_2'][resource.resource] = desiredReadingsScratchPad?.[resource.resource] !== reportedReadingsScratchPad[resource.resource];
            }
        });

        // Return results
        return {analogReading: reportedReading, pendingAnalogReading: desiredReading};
    }

    // -----------------------------------------------------------------------------------------------------
    // Build the analog form controls
    // -----------------------------------------------------------------------------------------------------
    public getDigitalFormControls(unit: Unit, channelIndex: number): PendingDigitalReadingResponse {
        let desiredProperties = null;
        let reportedProperties = null;

        if (unit && unit.unitSettings) {
            reportedProperties = unit.unitSettings?.['properties']?.['reported']?.['digital_input_31021'];
            desiredProperties = unit.unitSettings?.['properties']?.['desired']?.['digital_input_31021'];
        }

        const reportedReading = {} as DigitalReading;
        const desiredReading = {} as PendingDigitalReading;

        let index = `${channelIndex}`;
        if (unit.brand.unitStyleId == UnitStyleEnum.Dome  || unit.brand.unitStyleId == UnitStyleEnum.IOModule)
        {
            index = `100${channelIndex}`
        }

        const reportedChannel = reportedProperties?.[index];
        const desiredChannel = desiredProperties?.[index];

        reportedReading.channel_1 = reportedChannel ? +reportedChannel?.channel_1 : channelIndex;

        // name_0
        reportedReading.name_0 = reportedChannel?.name_0 ?? `Channel ${channelIndex}`;
        desiredReading.name_0 = desiredChannel?.name_0 ?? reportedReading.name_0;

        // accumulator_enable_7
        reportedReading.accumulator_enable_7 = reportedChannel?.accumulator_enable_7 ?? false;
        if (desiredChannel?.accumulator_enable_7 !== undefined) {
            desiredReading.accumulator_enable_7 = desiredChannel?.accumulator_enable_7 !== reportedReading.accumulator_enable_7;
        }

        // count_10
        reportedReading.count_10 = reportedChannel?.count_10 ?? 10;
        if (desiredChannel?.count_10 !== undefined) {
            desiredReading.count_10 = +desiredChannel?.count_10 !== reportedReading.count_10;
        }

        // count_alarm_enable_58
        reportedReading.count_alarm_enable_58 = reportedChannel?.count_alarm_enable_58 ?? false;
        if (desiredChannel?.count_alarm_enable_58 !== undefined) {
            desiredReading.count_alarm_enable_58 = desiredChannel?.count_alarm_enable_58 !== reportedReading.count_alarm_enable_58;
        }

        // count_alarm_value_57
        reportedReading.count_alarm_value_57 = reportedChannel?.count_alarm_value_57 ?? 100;
        if (desiredChannel?.count_alarm_value_57 !== undefined) {
            desiredReading.count_alarm_value_57 = +desiredChannel?.count_alarm_value_57 !== reportedReading.count_alarm_value_57;
        }

        // reset_mode_11
        reportedReading.reset_mode_11 = reportedChannel?.reset_mode_11 ?? 0;
        if (desiredChannel?.reset_mode_11 !== undefined) {
            desiredReading.reset_mode_11 = +desiredChannel?.reset_mode_11 !== reportedReading.reset_mode_11;
        }

        // reset_at_12
        reportedReading.reset_at_12 = reportedChannel?.reset_at_12 ?? 100;
        if (desiredChannel?.reset_at_12 !== undefined) {
            desiredReading.reset_at_12 = +desiredChannel?.reset_at_12 !== reportedReading.reset_at_12;
        }

        // reset_to_13
        reportedReading.reset_to_13 = reportedChannel?.reset_to_13 ?? 0;
        if (desiredChannel?.reset_to_13 !== undefined) {
            desiredReading.reset_to_13 = +desiredChannel?.reset_to_13 !== reportedReading.reset_to_13;
        }

        // hi_alarm_enabled_51
        reportedReading.hi_alarm_enabled_51 = +reportedChannel?.hi_alarm_enabled_51 ?? 0;
        if (desiredChannel?.hi_alarm_enabled_51 !== undefined) {
            desiredReading.hi_alarm_enabled_51 = +desiredChannel?.hi_alarm_enabled_51 !== reportedReading.hi_alarm_enabled_51;
        }

        // lo_alarm_enabled_54
        reportedReading.lo_alarm_enabled_54 = +reportedChannel?.lo_alarm_enabled_54 ?? 0;
        if (desiredChannel?.lo_alarm_enabled_54 !== undefined) {
            desiredReading.lo_alarm_enabled_54 = +desiredChannel?.lo_alarm_enabled_54 !== reportedReading.lo_alarm_enabled_54;
        }

        // alarm_persistence_50
        reportedReading.alarm_persistence_50 = +reportedChannel?.alarm_persistence_50 ?? 60;
        if (desiredChannel?.alarm_persistence_50 !== undefined) {
            desiredReading.alarm_persistence_50 = +desiredChannel?.alarm_persistence_50 !== reportedReading.alarm_persistence_50;
        }

        return {
            digitalReading: reportedReading,
            pendingDigitalReading: desiredReading
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // Gets the resources from the analog_acdc_31032 OMA
    // -----------------------------------------------------------------------------------------------------
    public getAnalogChannelResourceList(): OmaResource[] {
        return [
            {
                resource: 'name_0',
                defaultValue: null,
                dataType: 'string'
            },
            {
                resource: 'channel_enable_10',
                defaultValue: false,
                dataType: 'string'
            },
            {
                resource: 'alarm_persistence_dc_50',
                defaultValue: 1,
                dataType: 'number'
            },
            {
                resource: 'hi_alarm_threshold_dc_51',
                defaultValue: 1,
                dataType: 'number'
            },
            {
                resource: 'hi_alarm_deadband_dc_52',
                defaultValue: 1,
                dataType: 'number'
            },
            {
                resource: 'hi_alarm_enable_dc_53',
                defaultValue: 1,
                dataType: 'number'
            },
            {
                resource: 'lo_alarm_threshold_dc_57',
                defaultValue: 1,
                dataType: 'number'
            },
            {
                resource: 'lo_alarm_deadband_dc_58',
                defaultValue: 1,
                dataType: 'number'
            },
            {
                resource: 'lo_alarm_enable_dc_59',
                defaultValue: 1,
                dataType: 'number'
            },
            {
                resource: 'alarm_persistence_ac_70',
                defaultValue: 1,
                dataType: 'number'
            },
            {
                resource: 'hi_alarm_threshold_ac_71',
                defaultValue: 1,
                dataType: 'number'
            },
            {
                resource: 'hi_alarm_deadband_ac_72',
                defaultValue: 1,
                dataType: 'number'
            },
            {
                resource: 'hi_alarm_enable_ac_73',
                defaultValue: 1,
                dataType: 'number'
            },
            {
                resource: 'lo_alarm_threshold_ac_77',
                defaultValue: 1,
                dataType: 'number'
            },
            {
                resource: 'lo_alarm_deadband_ac_78',
                defaultValue: 1,
                dataType: 'number'
            },
            {
                resource: 'lo_alarm_enable_ac_79',
                defaultValue: 1,
                dataType: 'number'
            }];
    }

    // -----------------------------------------------------------------------------------------------------
    // Gets the resources from the analog_acdc_31032 OMA scratch pad
    // -----------------------------------------------------------------------------------------------------
    public getAnalogChannelScratchPadResourceList(): OmaResource[] {
        return [
            {
                resource: 'ac_input_type',
                defaultValue: 1,
                dataType: 'number'
            },
            {
                resource: 'ac_decimal_places',
                defaultValue: 4,
                dataType: 'number'
            },
            {
                resource: 'dc_input_type',
                defaultValue: 1,
                dataType: 'number'
            },
            {
                resource: 'dc_decimal_places',
                defaultValue: 4,
                dataType: 'number'
            },
            {
                resource: 'instant_off_enabled',
                defaultValue: false,
                dataType: 'number'
            },
            {
                resource: 'shunt_enabled',
                defaultValue: false,
                dataType: 'number'
            },
            {
                resource: 'shunt_volt_input',
                defaultValue: 50,
                dataType: 'number'
            },
            {
                resource: 'shunt_amps_scaled',
                defaultValue: 100,
                dataType: 'number'
            },
            {
                resource: 'scale_max_from',
                defaultValue: 100,
                dataType: 'number'
            },
            {
                resource: 'scale_max_to',
                defaultValue: 100,
                dataType: 'number'
            },
            {
                resource: 'scale_min_from',
                defaultValue: 0,
                dataType: 'number'
            },
            {
                resource: 'scale_min_to',
                defaultValue: 0,
                dataType: 'number'
            }];
    }

    // -----------------------------------------------------------------
    // Attempts to set a number is applicable
    //  -----------------------------------------------------------------
    setValue(request: any, dataType: string): any {
        if (dataType === 'boolean') {
            return request as boolean;
        }
        if (dataType === 'number') {
            return +request as number;
        }
        if (dataType === 'string') {
            return request as string;
        }
        return isNaN(request) ? request : +request;
    }

    // -----------------------------------------------------------------
    // Patches the Unit Data Point
    // -----------------------------------------------------------------
    async patchUnitDataPointAsync(payload: any[], unitId: number, datapointId: number): Promise<void> {
        const unitDataPointQuery = `${environment.bullhornApiUrl}/UnitDataPoints/PatchUnitDataPointAsync?unitId=${unitId}&dataPointId=${datapointId}`;
        await this._apiService.patchAsync<Response<UnitDataPoint>>(unitDataPointQuery, payload).then()
        .catch((err) => this.handleError(err));
    }

    // -----------------------------------------------------------------
    // Patches the Child Unit Data Point
    // -----------------------------------------------------------------
    async PatchChildUnitDataPointAsync(payload: any[], unitId: number, datapointId: number, instanceId: number): Promise<void> {
        const unitDataPointQuery = `${environment.bullhornApiUrl}/UnitDataPoints/PatchChildUnitDataPointAsync?unitId=${unitId}&dataPointId=${datapointId}&instanceId=${instanceId}`;
        await this._apiService.patchAsync<Response<UnitDataPoint>>(unitDataPointQuery, payload).then()
        .catch((err) => this.handleError(err));
    }

    // -----------------------------------------------------------------
    // Applies the scaling to the alarm
    // -----------------------------------------------------------------
    applyScaling(value: number, originalForm: FormGroup, updatedForm: FormGroup, electricalCurrent: string, isThreshold: boolean): number {
        const scalingEnabled = originalForm.controls?.['shunt_enabled']?.value;
        const electricPotential = (updatedForm.controls?.[`${electricalCurrent}_input_type`]?.value ?? originalForm.controls?.[`${electricalCurrent}_input_type`]?.value) == 1 ? InputTypeEnum.volts : InputTypeEnum.milliVolts;
        let multiplier: number = electricPotential === InputTypeEnum.volts ? MultiplierConstants.voltsMultiplier : MultiplierConstants.milliVoltsMultiplier;
        
        if (electricalCurrent === 'ac' || !scalingEnabled) {
            return Math.ceil(value * multiplier);
        }
        if (electricalCurrent === 'dc' && electricPotential === InputTypeEnum.volts && isThreshold) {
            const toMin = updatedForm.controls?.scale_min_from?.value ?? originalForm.controls?.scale_min_from?.value;
            const toMax = updatedForm.controls?.scale_max_from?.value ?? originalForm.controls?.scale_max_from?.value;
            const fromMin  = updatedForm.controls?.scale_min_to?.value ?? originalForm.controls?.scale_min_to?.value;
            const fromMax = updatedForm.controls?.scale_max_to?.value ?? originalForm.controls?.scale_max_to?.value;
            const scaledValue = this.scale(value, fromMin, fromMax, toMin, toMax);
            return Math.ceil(scaledValue * multiplier);
        }
        else if (electricalCurrent === 'dc' && electricPotential === InputTypeEnum.volts && !isThreshold) {
            const toMin = updatedForm.controls?.scale_min_from?.value ?? originalForm.controls?.scale_min_from?.value;
            const toMax = updatedForm.controls?.scale_max_from?.value ?? originalForm.controls?.scale_max_from?.value;
            const fromMin  = updatedForm.controls?.scale_min_to?.value ?? originalForm.controls?.scale_min_to?.value;
            const fromMax = updatedForm.controls?.scale_max_to?.value ?? originalForm.controls?.scale_max_to?.value;
            const scaledValue = this.setScale(value, fromMin, fromMax, toMin, toMax);
            return Math.ceil(scaledValue * multiplier);
        }
        if (electricalCurrent === 'dc' && electricPotential === InputTypeEnum.milliVolts) {
            let input = updatedForm.controls?.shunt_volt_input?.value ?? originalForm.controls?.shunt_volt_input?.value;
            let scale = updatedForm.controls?.shunt_amps_scaled?.value ?? originalForm.controls?.shunt_amps_scaled?.value;
            if (input == undefined || input == null) {
                input = updatedForm.controls?.scale_max_from?.value ?? originalForm.controls?.scale_max_from?.value;
            }
            if (scale == undefined || scale == null) {
                scale = updatedForm.controls?.scale_max_to?.value ?? originalForm.controls?.scale_max_to?.value;
            }
            const scaledValue = this.scaleMiliVolts(value, input, scale);
            return Math.ceil(scaledValue * multiplier);
        }
    }

    // -----------------------------------------------------------------
    // Removes the scaling from the alarm
    // -----------------------------------------------------------------
    removeScaling(value: number, scratchPad: AnalogScratchPad, input: string, isThreshold: boolean): number {
        const scalingEnabled: boolean = scratchPad.shunt_enabled;
        const electricPotential = scratchPad?.[`${input}_input_type`] === 1 ? InputTypeEnum.volts : InputTypeEnum.milliVolts;
        let divider = electricPotential === InputTypeEnum.volts ? MultiplierConstants.voltsMultiplier : MultiplierConstants.milliVoltsMultiplier;
        if (input === 'ac' || !scalingEnabled) {
            return value / divider;
        }
        if (input === 'dc' && electricPotential === InputTypeEnum.volts && isThreshold) {
            const toMin  = scratchPad?.scale_min_from;
            const toMax  = scratchPad?.scale_max_from;
            const fromMin = scratchPad?.scale_min_to;
            const fromMax = scratchPad?.scale_max_to;
            const inputValue = value / divider;
            return this.scale(inputValue, toMin, toMax, fromMin, fromMax);
        } 
        else if (input === 'dc' && electricPotential === InputTypeEnum.volts && !isThreshold) {
            const fromMin  = scratchPad?.scale_min_from;
            const fromMax  = scratchPad?.scale_max_from;
            const toMin = scratchPad?.scale_min_to;
            const toMax = scratchPad?.scale_max_to;
            const inputValue = value / divider;
            return this.decodeScale(inputValue, toMin, toMax, fromMin, fromMax);
        }
        if (input === 'dc' && electricPotential === InputTypeEnum.milliVolts) {
            const inputV = scratchPad?.shunt_volt_input;
            const scale = scratchPad?.shunt_amps_scaled;
            const inputValue = value / divider;
            return inputValue/(inputV/scale);
        }
    }

    // -----------------------------------------------------------------
    // The formula to scale from min to max
    // -----------------------------------------------------------------
    setScale(value, fromMin, fromMax, toMin, toMax): number {
        var calcValue = value / (fromMax - fromMin);
        let positivetoMax: number = Math.abs(toMax);
        let positivetoMin: number = Math.abs(toMin);
        var result = calcValue * (positivetoMax + positivetoMin);
        return result;
    }

    // -----------------------------------------------------------------
    // The formula to scale from min to max
    // -----------------------------------------------------------------
    decodeScale(value, fromMin, fromMax, toMin, toMax): number {
        var calcValue = value * (fromMax - fromMin);
        let positivetoMax: number = Math.abs(toMax);
        let positivetoMin: number = Math.abs(toMin);
        var result = calcValue / (positivetoMax + positivetoMin);
        return result;
    }

    // -----------------------------------------------------------------
    // The formula to scale from min to max
    // -----------------------------------------------------------------
    scale(value, fromMin, fromMax, toMin, toMax): number {
        return ((((+value - +fromMin) * (+toMax - +toMin)) / (+fromMax - +fromMin)) +toMin);
    }

    // -----------------------------------------------------------------
    // The formula to scale milivolts
    // -----------------------------------------------------------------
    scaleMiliVolts(value, input, scale): number {
        return (input/scale)*value;
    }

    // -----------------------------------------------------------------
    // Encodes the scratchpad values to reduce the size
    //  -----------------------------------------------------------------
    encodeScratchPad(scratchpad: AnalogScratchPad): string {
        let scratchpadObj = {};
        Object.keys(scratchpad).forEach((key) => {
            if (AnalogScratchpadEnum[key]) {
                scratchpadObj[`s${AnalogScratchpadEnum[key]}`] = scratchpad[key];
            }
        });
        return JSON.stringify(scratchpadObj);
    }

    // -----------------------------------------------------------------
    // Decodes the scratchpad values from the old format to the new format
    //  -----------------------------------------------------------------
    decodeScratchPad(scratchpad: any): AnalogScratchPad {
        let result = {} as AnalogScratchPad;
        if (scratchpad == undefined)
        {
            return result;
        }
        if (!JSON.parse(scratchpad)['ac_input_type']) {
            // Convert to the human-readable AnalogScratchPad
            const scratchpadObj = JSON.parse(scratchpad);
            Object.keys(scratchpadObj).forEach((key) => {
                result[AnalogScratchpadEnum[+key.replace(/[a-z]/gi, '')]] = scratchpadObj[key];
            });
        } else {
            result = JSON.parse(scratchpad) as AnalogScratchPad;
        }
        return result;
    }

    // -----------------------------------------------------------------
    // Gets the pcs labels
    // -----------------------------------------------------------------
    async getPcsLabelsByBrandId(brandId: number): Promise<PcsLabel[]> {
        const request = await this._apiService.getAsync<Response<PcsLabel[]>>(`${environment.bullhornApiUrl}/PcsLabels/GetPcsLabelsAsync?BrandIds=${brandId}`).then();
        return request.result;
    }

    // -----------------------------------------------------------------
    // Get Datapoint
    // -----------------------------------------------------------------
    getDataPoint(unitDataPoints, datapointLookup: string): ChannelReadingsViewModel {
        return _.find(unitDataPoints, {dataPointId: this._dataPointEnum[datapointLookup]});
    }

    // -----------------------------------------------------------------
    // Generic Method to Handle Error and throwError - Best use for handling observable pipes operation
    // -----------------------------------------------------------------
    protected handleError(error: any) {
        console.log(error);
        return throwError(() => error);
    }
}
